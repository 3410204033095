<template>
  <a-modal
    :visible="visible"
    class="dialog-box"
    width="640px"
    :okText="okText"
    cancelText="取消"
    :confirm-loading="confirmLoading"
    centered
    title="导出译稿"
    @cancel="cancel"
  >
    <div v-if="exportType == 3">是否确认导出并下载该译稿</div>
    <div v-else>
      <div class="radio-box">
        <div
          class="radio-item"
          :class="{ 'radio-item-selected': exportRadio == 1 }"
          @click="setExportRadio(1)"
        >
          <div class="vip-logo">
            <img
              src="@/images/vipVersion.png"
              v-if="
                $userLevelPrivilege.privilege.export_format
                  .split(',')
                  .indexOf(vipKey[1]) < 0
              "
            />
          </div>
          <div class="radio-item-img">
            <img src="./image/yiwen.png" />
          </div>
          <div class="radio-item-text">
            纯译文
          </div>
          <img
            class="selected"
            src="./image/selected.png"
            v-show="exportRadio == 1"
          />
        </div>

        <div
          class="radio-item"
          :class="{ 'radio-item-selected': exportRadio == 2 }"
          @click="setExportRadio(2)"
          v-if="isNoSupportFile"
        >
          <div
            class="vip-logo"
            v-if="
              $userLevelPrivilege.privilege.export_format
                .split(',')
                .indexOf(vipKey[2]) < 0
            "
          >
            <img src="@/images/vipVersion.png" />
          </div>
          <div class="radio-item-img">
            <img src="./image/duanduan1.png" />
          </div>
          <div class="radio-item-text">
            段段对照
          </div>
          <div class="radio-item-add">
            <div>原文在上</div>
            <div>译文在下</div>
          </div>
          <img
            class="selected"
            src="./image/selected.png"
            v-show="exportRadio == 2"
          />
        </div>

        <div
          class="radio-item"
          :class="{ 'radio-item-selected': exportRadio == 5 }"
          @click="setExportRadio(5)"
          v-if="isNoSupportFile"
        >
          <div
            class="vip-logo"
            v-if="
              $userLevelPrivilege.privilege.export_format
                .split(',')
                .indexOf(vipKey[5]) < 0
            "
          >
            <img src="@/images/vipVersion.png" />
          </div>
          <div class="radio-item-img">
            <img src="./image/duanduan2.png" />
          </div>
          <div class="radio-item-text">
            段段对照
          </div>
          <div class="radio-item-add">
            <div>译文在上</div>
            <div>原文在下</div>
          </div>
          <img
            class="selected"
            src="./image/selected.png"
            v-show="exportRadio == 5"
          />
        </div>

        <div
          class="radio-item"
          :class="{ 'radio-item-selected': exportRadio == 3 }"
          @click="setExportRadio(3)"
        >
          <div
            class="vip-logo"
            v-if="
              $userLevelPrivilege.privilege.export_format
                .split(',')
                .indexOf(vipKey[3]) < 0
            "
          >
            <img src="@/images/vipVersion.png" />
          </div>
          <div class="radio-item-img">
            <img src="./image/binglie.png" />
          </div>
          <div class="radio-item-text">
            并列对照
          </div>
          <div class="radio-item-add">
            <div>Excel文件</div>
            <div>原译各一列</div>
          </div>
          <img
            class="selected"
            src="./image/selected.png"
            v-show="exportRadio == 3"
          />
        </div>

        <div
          class="radio-item"
          :class="{ 'radio-item-selected': exportRadio == 6 }"
          @click="setExportRadio(6)"
        >
          <div
            class="vip-logo"
            v-if="
              $userLevelPrivilege.privilege.export_format
                .split(',')
                .indexOf(vipKey[6]) < 0
            "
          >
            <img src="@/images/vipVersion.png" />
          </div>
          <div class="radio-item-img">
            <img src="./image/yuanwen.png" />
          </div>
          <div class="radio-item-text">
            纯原文
          </div>
          <div class="radio-item-add">
            <div>编辑后的原文</div>
          </div>
          <img
            class="selected"
            src="./image/selected.png"
            v-show="exportRadio == 6"
          />
        </div>
      </div>
      <div v-if="store.state.taskDetail.docTypes.split(',').some((item)=>{
        return item.includes('txt')
      })" class="margin-b-12">
        <span style="color: #333333;display: inline-block;width: 80px">TXT文件：</span>
        <a-checkbox v-model:checked="saveAsTxt" style="color: #666666;">
          导出为TXT格式（不勾选则导出为Word格式）
        </a-checkbox>
      </div>
      <div>
        <span style="color: #333333;display: inline-block;width: 80px;">标记漏译：</span>
        <a-checkbox v-model:checked="withMissMark" style="color: #666666;">
          译文漏译的句段有黄色底色（仅word）
        </a-checkbox>
      </div>
      <div v-if="isNoSupportFile" class="margin-t-12">
        <span style="color: #333333;display: inline-block;width: 80px;">导出备注：</span>
        <a-checkbox
          v-model:checked="exportRemarkChecked"
          style="color: #666666;"
        >
          导出时带上【备注】。
        </a-checkbox>
        <a
          style="color:#00BEFA;text-decoration: underline"
          @click="openExportRemarkModal"
        >
          备注格式说明
        </a>
      </div>
      <div v-if="exportRadio == 5 || exportRadio == 2" class="margin-t-12">
        <span style="color: #333333;display: inline-block;width: 80px">段段对照：</span>
        <a-checkbox
          v-model:checked="notSetSameParaChecked"
          style="color: #666666;"
        >
          原文段落与译文完全相同时，两段合并为一段进行导出
        </a-checkbox>
      </div>
    </div>
    <template #footer>
      <div class="display-flex justify-between">
        <div>
          <!--包含dxf导出才显示这个-->
          <div class="display-flex align-center font-size-14" v-if="exportType == 2">
            <InfoCircleOutlined style="color:#000;font-size: 13px"></InfoCircleOutlined>
            <span class="color-666 margin-r-4 margin-l-4">导出格式限制说明</span>
            <a-popover placement="top">
              <template #content>
                <div class="font-size-13 color-333 margin-b-8"><b>以下2类格式文档，导出时<span class="color-F55C3F">不支持段段对照</span></b></div>
                <div class="font-size-13 color-666" style="line-height: 20px">
                  <div>CAD文件：dxf</div>
<!--                  <div>网页文件：html，htm</div>-->
                  <div>本地化文件：json，properties，yaml，yml</div>
                </div>
              </template>
              <a style="color:#00B4FA;text-decoration: underline">查看</a>
              <!--            <template #title>-->
              <!--              <span>Title</span>-->
              <!--            </template>-->
            </a-popover>
          </div>
        </div>
        <div>
          <a-button @click="cancel">取消</a-button>
          <a-button type="primary" @click="handleOk" :loading="confirmLoading">{{okText}}</a-button>
        </div>
      </div>
    </template>
  </a-modal>

  <a-modal
    :visible="exportRemarkVisible"
    okText="确认"
    cancelText="取消"
    centered
    title="导出备注说明"
    @ok="exportRemarkVisible = false"
    @cancel="exportRemarkVisible = false"
  >
    <div>
      <div>
        <p class="font-size-14 color-333">
          <strong>1、导出并列对照格式时：</strong>
        </p>
        <p class="font-size-14 color-333" style="padding-left: 22px">
          备注会在原文/译文列后方
          <span class="color-00C6F3">新增一列备注导出</span>
          ；
        </p>
        <div class="margin-t-15 margin-b-25">
          <img src="./image/tip1.png" width="500" />
        </div>
        <p class="font-size-14 color-333">
          <strong>2、导出纯译文、段段对照、纯原文格式时：</strong>
        </p>
        <p class="font-size-14 color-333" style="padding-left: 22px">
          备注会以
          <span class="color-00C6F3">批注的形式</span>
          导出（ 仅支持原文件为Word、Excel和PPT）。
        </p>
        <div class="margin-t-15">
          <img src="./image/tip2.png" width="500" />
        </div>
      </div>
    </div>
  </a-modal>
</template>

<script>
  import { useI18n } from 'vue-i18n'
  import { reactive, toRefs, computed, onBeforeMount } from 'vue'
  import { useStore } from 'vuex'
  import {
    startFileExport,
    getDocExportCacheList,
    existsScoreByDocId,
  } from '@/http/api'
  import { message } from 'ant-design-vue'
  import {InfoCircleOutlined} from '@ant-design/icons-vue';


  export default {
    props: {
      visible: {
        type: Boolean,
        default: false,
      },
      exportType:{
        type: Number,
        default: 1
      }
    },
    components:{
      InfoCircleOutlined
    },
    setup(props, ctx) {
      const { t } = useI18n()
      const store = useStore()
      const state = reactive({
        okText: `导出并下载`,
        confirmLoading: false,
        taskDetail: store.state.taskDetail,
        exportRadio: 1,
        vipKey: {
          1: 'cyw',
          2: 'dddzywzx',
          3: 'bldz',
          5: 'dddzywzs',
          6: 'plaintext',
        },
        exportRemarkChecked: false,
        exportRemarkVisible: false,
        notSetSameParaChecked: true,
        withMissMark: true,
      })


      const saveAsTxt = computed({
        get() {
          return store.state.modal.saveAsTxt
        },
        set(val) {
          store.commit('modal/changeKey', {
            saveAsTxt:val
          })
        },
      })

      // userLevelPrivilege
      const $userLevelPrivilege = computed(() => {
        return store.state.userLevelPrivilege
      })

      const openExportRemarkModal = () => {
        state.exportRemarkVisible = true
      }
      const setExportRadio = (type) => {
        if (
          $userLevelPrivilege.value.privilege.export_format
            .split(',')
            .indexOf(state.vipKey[type]) > -1
        ) {
          state.exportRadio = type
        } else {
          window.$levelMessage({
            msg: `${$userLevelPrivilege.value.nameTeam ||
              $userLevelPrivilege.value.name}不可导出该格式`,
          })
        }
      }

      const cancel = () => {
        localStorage.setItem("notNewSetSameParaChecked",state.notSetSameParaChecked?'1':'2')
        state.okText = `导出并下载`
        ctx.emit('update:visible', false)
      }

      const httpGetDocExportCacheList = (ids) => {
        let localWindows = window
        getDocExportCacheList({ ids: ids })
          .then((res) => {
            let data = res.data.data[0] || ''
            if (data == '') {
              message.error('导出失败')
              return false
            }
            if (data.exportStatus != 2) {
              setTimeout(() => {
                httpGetDocExportCacheList(ids)
              }, 2000)
              return false
            }
            if (data.exportStatus == 2) {
              // if (state.taskDetail.twinslatorId && state.taskDetail.isSelfWork) {
              //   let params = {
              //     docId: state.taskDetail.docId,
              //     twinslatorId: state.taskDetail.twinslatorId
              //   };
              //   existsScoreByDocId(params).then(res => {
              //     if (res.data.data.existsScore === false) {
              //       store.commit("changeKey", {
              //         engineGradeVisible: true
              //       });
              //     }
              //   });
              // }
              state.confirmLoading = false
              cancel()
              localWindows.open(
                data.fileUrl,
                '_self',
                'height=100, width=400, top=0, left=0, toolbar=no, menubar=no,scrollbars=no, resizable=no,location=n o, status=no'
              )
            }
          })
          .catch(() => {
            cancel()
            // state.confirmLoading = true;
            // state.okText = "导出"
          })
      }

      // console.log(props.exportType);

      const isNoSupportFile = computed(() => {
        // let lastName = store.state.taskDetail.docName.substring(
        //   store.state.taskDetail.docName.lastIndexOf('.')
        // )
        return props.exportType == 1;
      })

      const handleOk = async () => {
        let notSetSameParaChecked = false
        if (state.exportRadio == 2 || state.exportRadio == 5) {
          notSetSameParaChecked = state.notSetSameParaChecked
        }

        const res = await startFileExport({
          taskIds: state.taskDetail.taskId,
          exportType:state.taskDetail.docParseType == 5 ? 4 : state.exportRadio,
          withComment: state.exportRemarkChecked,
          withMissMark: state.withMissMark,
          notSetSamePara: notSetSameParaChecked,
          saveAsTxt:saveAsTxt.value
        })

        if (res.data.result == '1') {
          let ids = res.data.data
          state.confirmLoading = true
          state.okText = '正在导出'
          httpGetDocExportCacheList(ids)
          return
        }
        cancel()
      }

      onBeforeMount(() => {
        let value = localStorage.getItem("notNewSetSameParaChecked");
        if (value == '2') {
          state.notSetSameParaChecked = false
        }
      })

      return {
        saveAsTxt,
        store,
        openExportRemarkModal,
        setExportRadio,
        $userLevelPrivilege,
        isNoSupportFile,
        ...toRefs(state),
        handleOk,
        cancel,
        t,
      }
    },
  }
</script>

<style lang="scss" scoped>
  .color-333 {
    color: #333;
  }

  .color-00C6F3 {
    color: #00c6f3;
  }

  .dialog-box {
    h4 {
      span {
        margin-left: 10px;
        font-size: 12px;
        color: #666;
      }
    }

    .text-add {
      height: 22px;
      font-size: 12px;
      font-weight: 400;
      color: #666666;
      line-height: 22px;
    }

    .radio-box {
      margin-bottom: 20px;
      display: flex;
      justify-content: center;
      align-items: center;

      .radio-item {
        cursor: pointer;
        border-radius: 8px;
        width: 104px;
        height: 162px;
        text-align: center;
        padding-top: 24px;
        margin: 0 10px;
        position: relative;
        border: 1px solid #eeeeee;
        position: relative;

        .vip-logo {
          position: absolute;
          left: 50%;
          top: -18px;
          transform: translate(-50%, 0);

          img {
            width: 36px;
            height: 36px;
          }
        }

        .radio-item-img {
          margin-bottom: 12px;

          img {
            width: 40px;
            height: 40px;
          }
        }

        .radio-item-text {
          margin-bottom: 8px;
          height: 20px;
          font-size: 14px;
          font-weight: 600;
          color: #333333;
          line-height: 20px;
          margin-top: 12px;
        }

        .radio-item-add {
          color: #999999;
          font-size: 12px;
          line-height: 18px;
        }

        .selected {
          position: absolute;
          bottom: 0;
          right: 0;
          width: 24px;
          height: 24px;
        }
      }

      .radio-item-selected {
        background: #f9feff;
        border: 1px solid #00c6f3;
      }
    }
  }
</style>
