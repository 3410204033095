import { Modal, message } from 'ant-design-vue'
import { getQueryString } from '@/plugins/prototype'
import {
  getPreviewDetail,
  getUserLevelPrivilege,
  getLastModifiedSent,
  getDomainDetail,
} from '@/http/api'
import { $storageUser } from '@/plugins/proxyStorage'
import { getDecorateList } from '../http/api'
import { positionSentence } from '@/plugins/comm'
import {deleteNotUsedDb, initDB} from '@/indexDB/indexDB.js'

import {
  initAutoCommit,
  timeIntervalInspect,
} from '@/indexDB/autoCommit.js'

export default async function init(store) {
  const isPreview = getQueryString('isPreview')
  const splitIndex = getQueryString('splitIndex')
  //是否是审校模式
  // const isCorpus = getQueryString('isCorpus')

  const initStorage = () => {
    if ($storageUser.userId != store.state.taskDetail.userId) {
      //记录存储的值 userId 存储的时候已经自动初始化
      $storageUser.userId = store.state.taskDetail.userId
    }
  }

  //预览模式
  if (isPreview === 'true') {
    //获取按钮配置
    store.dispatch('getPreviewModelOptionResource')
    getUserLevelPrivilege({}).then((level) => {
      store.commit('changeKey', {
        userLevelPrivilege: level.data.data,
      })
      getPreviewDetail({ splitIndex: splitIndex }).then((res) => {

        //根据返回要提示的原因，来直接toast提示出来，如果没有则不提示；
        if (res.data.data.reason) {
          message.warning(res.data.data.reason);
        }

        //获取语种
        store.dispatch('getLanguageList')
        //存储taskDetail信息
        store.commit('changeKey', {
          taskDetail: res.data.data,
        })
        getDomainDetail({ enterpriseId: res.data.data.enterpriseId }).then(
          (result) => {
            let settings = result.data.data
            store.commit('changeKey', {
              settings,
            })
            let link = document.querySelector('link[rel*="icon"]')
            link.href = settings.logo
          }
        )
        store.dispatch('getSentence',{isBackTop:true})
        // store.dispatch("get")
        //初始化本地存储用户信息以及差异对比开关操作
        initStorage();

        //根据源语种方向和目标语种方向来确定值
        if (store.state.specialLanguageList.includes(store.state.taskDetail.sourceLangId)) {
          document.body.classList.add("source-language-text-2");
        } else {
          document.body.classList.add("source-language-text-1");
        }
        if (store.state.specialLanguageList.includes(store.state.taskDetail.targetLangId)) {
          document.body.classList.add("target-language-text-2");
        } else {
          document.body.classList.add("target-language-text-1");
        }
      })
    })
  } else {
    //正常模式 获取用户权限
    getUserLevelPrivilege({}).then((res) => {
      store.commit('changeKey', {
        userLevelPrivilege: res.data.data,
      })
      //获取润色列表
      getDecorateList().then((res) => {
        let list = res.data.data.list
        let levelObj = {}
        list.forEach((item) => {
          levelObj[item.code] = item.description
        })
        store.commit('changeKey', {
          decorationLevelObj: levelObj,
        })
      })

      //获取任务信息
      store.dispatch('getTaskDetail').then((res) => {
        //获取域名信息
        getDomainDetail({ enterpriseId: res.enterpriseId }).then((res) => {
          let settings = res.data.data
          store.commit('changeKey', {
            settings,
          })
          let link = document.querySelector('link[rel*="icon"]')
          link.href = settings.logo
        })

        //获取按钮配置
        if (res.taskStatus == 2) {
          store.dispatch('getPreviewModelOptionResource')
        } else {
          //初始化数据库
          initDB();
          //初始化自动提交；
          initAutoCommit();
          //删除未使用的数据库；
          deleteNotUsedDb();
          // //普通模式进来触发循环检查
          timeIntervalInspect()
          store.dispatch('getOptionResource')
        }
        if (res.taskClassify == 8) {
          store.commit('excelProject/replaceFilterArr', {
            activityWorkflow: res.activityWorkflow,
          })
        }
        //获取语种
        store.dispatch('getLanguageList')
        //获取行业
        store.dispatch('getIndustryInfoList')
        //获取QA配置
        store.dispatch('rightToolbar/getQASetting')


        //获取最后一句更新的句子；直接定位过去
        getLastModifiedSent({ sentListSize: store.state.pageSize }).then(
          (res) => {
            let result = res.data.data
            if (result.modified) {
              store.commit('changeKey', {
                pageNum: result.page,
              })
              store.dispatch('getSentence', { sentencePosition: result.sentId}).then((res) => {
                  positionSentence(result.sentId)
                })
              return
            }
            store.dispatch('getSentence', {isBackTop:true}).then((res) => {
              let tableItem =
                store.state.excelProject.docData.sentenceDTOList[0] || {}
              store.commit('changeKey', {
                checkedDetail: {
                  ...tableItem,
                },
              })
            })
          }
        )
        //初始化本地存储用户信息以及差异对比开关操作
        initStorage()

        store.dispatch('checkProgress') //请求qa预览进度


        //根据源语种方向和目标语种方向来确定值
        if (store.state.specialLanguageList.includes(store.state.taskDetail.sourceLangId)) {
          document.body.classList.add("source-language-text-2");
        } else {
          document.body.classList.add("source-language-text-1");
        }
        if (store.state.specialLanguageList.includes(store.state.taskDetail.targetLangId)) {
          document.body.classList.add("target-language-text-2");
        } else {
          document.body.classList.add("target-language-text-1");
        }
      })
    })
  }
}
