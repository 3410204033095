import {confirmSentences, postTwinslatorCorpusMatch, postTwinslatorTermMatch} from '@/http/api'
import useUtils from '@/plugins/utils'
import {
  getCursorPosition,
  getRangePosition,
  rangeSelection,
  positionSelection,
  qaFormatText,
  imgFormatText,
} from '@/plugins/range.js'

const state = {
  //触发提交事件的时候 标识符（点击译文状态触发，和普通失去焦点触发）
  keyQueue: '',
  //原文
  sourceText: '',
  //译文
  targetText: '',
  // sentIds查询
  serialNum:'',
  //是否正则匹配
  sourceRegexSearch:false,
  targetRegexSearch:false,
  docData: {
    sentenceDTOList: [],
  },
  activityWorkflowKey: {
    PE: 'PEConfirm',
    PF: 'PFConfirm',
    PF1: 'PFConfirm',
    PF2: 'PF2Confirm',
    PF3: 'PF3Confirm',
  },
  ArrMap: new Map(),
  checkedList: [],
  checkedAll: false, //全选
  //原文预览设置位置（写好了本地存储实例）
  twinslatorMatch: {
    //术语匹配列表
    matchedTerm:[],
    //语料匹配列表
    matchedCorpus:[],
  },
  //差异对比开关 （控制开关处写好了本地存储实例）
  differentHighLightModel: false,
  //译文表格选中项
  tabActiveIndex: 0,
  //译文状态
  status: '',
  //译文来源
  targetFrom: '',
  //原文内容
  sourceTags: '',
  //带标记
  withFlag: '',
  //质检分数
  qualityScores: '',
  //是否折叠所有 / //是否展开所有,
  isCollapsed:false,
  //是否分组
  groupBy:null,
  groupSort:null,
  //筛选
  filterArr: [
    {
      title: '状态',
      key:'status',
      children: [
        { label: '已确认', isChecked: false, value: 'Confirm' },
        { label: '未确认', isChecked: false, value: 'UnConfirm' },
        { label: '空译文', isChecked: false, value: 'EmptyTranslation' },
        {
          label: "有译文",
          isChecked: false,
          value: "HasTranslation"
        }
      ],
    },
    {
      title: '译文来源',
      key:'targetFrom',
      children: [
        { label: 'MT(机器翻译)', isChecked: false, value: 'MT' },
        { label: 'PE(人工翻译)', isChecked: false, value: 'PE' },
        { label: 'PF(人工审校)', isChecked: false, value: 'PF',selfWorkHidden:true },
        { label: 'PM(项目经理)', isChecked: false, value: 'PM',selfWorkHidden:true },
        { label: 'UT(非译内容)', isChecked: false, value: 'NonTranslate',selfWorkHidden:true },
        { label: 'REP(重复)', isChecked: false, value: 'REPEAT',selfWorkHidden:true },
        { label: 'SRC(原文)', isChecked: false, value: 'SOURCE',selfWorkHidden:true },
        // { label: '99%~100%匹配', isChecked: false, value: 'Match99_100'},
        { isChecked: false,value:'Match',rangeValue:[99,100],selfWorkHidden:true,type:'rangeInput',},
        // { label: '50%~84%匹配', isChecked: false, value: 'PERCENT_99_MATCHING',selfWorkHidden:true},
      ],
    },
    {
      title: '原文内容',
      selfWorkHidden:true,
      key:'sourceTags',
      children: [
        { label: '原文重复', isChecked: false, value: 'SELF_REPEAT' },
        { label: '交叉文件重复', isChecked: false, value: 'CROSS_REPEAT' },
        { label: '挖空重复', isChecked: false, value: 'HAS_URT_REPEAT',tip: '单句段由文本和数值等非译内容组成，文本内容完全一致，仅数值等的非译内容不同',icon:true,isSupportUrtLock:true },
        { label: '不重复', isChecked: false, value: 'NO_REPEAT' },
      ],
    },
    {
      title: '带标记',
      key:'withFlag',
      children: [
        { label: '带备注', isChecked: false, value: 'hasComment' },
        { label: '已锁定', isChecked: false, value: 'isLocked', docLock: true },
        {
          label: '未锁定',
          isChecked: false,
          value: 'isUnLocked',
          docLock: true,
        },
        {
          label: '已解锁',
          isChecked: false,
          value: 'isTranslatorUnLock',
          docLock: true,
        },
        {
          label: '含样式',
          isChecked: false,
          value: 'hasTag',
        },
        {
          label: '带QA提示',
          isChecked: false,
          value: 'hasQA',
          disabled: true,
          tip: '功能升级中',
        },
      ],
    },
    {
      title: '质检',
      key:'qualityScores',
      children: [
        { label: '未打分', isChecked: false, value: -1 },
        { label: '0分', isChecked: false, value: 0 },
        { label: '1分', isChecked: false, value: 1 },
        { label: '2分', isChecked: false, value: 2 },
        { label: '3分', isChecked: false, value: 3 },
        { label: '4分', isChecked: false, value: 4 },
        { label: '5分', isChecked: false, value: 5 },
      ],
    },
  ],
}

const getters = {}
const mutations = {
  changeKey(state, payload) {
    for (let i in payload) {
      state[i] = null
      state[i] = payload[i]
    }
  },
  setTabActiveIndex(state, param) {
    state.tabActiveIndex = state.ArrMap.get(param.sentId)
  },

  //重复句填充
  repeatSentenceAdd(state, param) {
    let list = state.docData.sentenceDTOList
    // let ids = param.ids;
    //推送回来的数据
    let selection = window.getSelection();
    param.forEach((item) => {
      // console.log(item)
      if (state.ArrMap.has(item.sentId)) {
        let node = window.$getTargetDom(item.sentId);
        //是否进入提交保存操作?
        let listItem = list[state.ArrMap.get(item.sentId)]
        //重复句
        item.targetPlaceholder = item.targetPlaceholder || "";
        item.targetPlaceholder = window.$assignValueToSrc(item.targetPlaceholder);
        item.diffCompare = window.$assignValueToSrc(item.diffCompare);
        node.innerHTML = item.targetPlaceholder || ''
        listItem.targetType = item.targetType
        listItem.targetPlaceholder = item.targetPlaceholder
        listItem.diffCompare = item.diffCompare
        listItem.saveFailStatus = false
        listItem.compareTargetHTML = item.targetPlaceholder
        //填充的时候走判断流程 如果是当前句；则将光标移至末尾
        const { setFocusEnd, insertHtmlAtCaret } = useUtils()
        if (selection.type=='Caret' && node.contains(selection.focusNode)) {
          setFocusEnd(node,true);
        }
        // if ()
      }
    })
  },
  getFilterValue(state) {
    // 新值
    // status targetFrom sourceTags withFlag qualityScores
    state.filterArr.forEach((item) => {
      let arr = []
      item.children.forEach((citem) => {
        if (citem.isChecked) {
          if (citem.type=='rangeInput') {
            arr.push(`${citem.value}${citem.rangeValue[0]}_${citem.rangeValue[1]}`)
          } else {
            arr.push(citem.value)
          }
          // state.status = arr.join(",");
          // state.status =
          // state.sourceTags = state.sourceTags + `${state.sourceTags ? "," : ""}` + citem.value;
        }
      })
      state[item.key] = arr.join(',')
    })
  },
  replaceFilterArr(state, param) {
    let addArr = param.activityWorkflow.map((item) => {
      return {
        label: `${item}确认`,
        isChecked: false,
        value: state.activityWorkflowKey[item],
      }
    })
    addArr.push({
      label: "PM确认",
      isChecked: false,
      value: "PMConfirm"
    });
    addArr.push({
      label: "空译文",
      isChecked: false,
      value: "EmptyTranslation"
    });
    addArr.push({
      label: "有译文",
      isChecked: false,
      value: "HasTranslation"
    });
    addArr.unshift({ label: '未确认', isChecked: false, value: 'UnConfirm' })
    state.filterArr[0].children = [...addArr]
  },
  //重置过滤选项条件
  resetFilterValue(state) {
    state.sourceTags = '';
    state.qualityScores = '';
    state.targetFrom = '';
    state.withFlag = '';
    state.status = '';
    state.filterArr.forEach((item) => {
      item.children.forEach((citem) => {
        citem.isChecked = false
      })
    })
  },
  //重置过滤选项条件
  resetNoConfirm(state) {
    state.sourceTags = ''
    state.qualityScores = ''
    state.targetFrom = ''
    state.withFlag = 'isUnLocked'
    state.status = 'UnConfirm'
    state.filterArr.findIndex((item) => {
      item.children.findIndex((citem) => {
        if (citem.label == '未确认') {
          citem.isChecked = true
        }
        if (citem.label == '未锁定') {
          citem.isChecked = true
        }
      })
    })
  },
}
const actions = {
  //加载译文QA
  renderQATargetHtml({ rootState }, params) {
    let sentId = params.sentId
    if (!sentId) {
      //先取出一遍原文HTML //开始判断是否加载了术语？ 还是直接赋值？术语的值我应该存着？
      return
    }
    let sentenceIndex = rootState.excelProject.ArrMap.get(params.sentId)
    let sentenceItem =
      rootState.excelProject.docData.sentenceDTOList[sentenceIndex]
    // console.log(sentenceItem);

    if (!sentenceItem) {
      return
    }
    // console.log("执行QA画线");
    let targetNode = window.$getTargetDom(sentenceItem.sentId)

    if (sentenceItem.qaInfoList?.length) {
      //换成QAInfoList;遍历查找属于原文节点. 然后重新计算。
      let arr = sentenceItem.qaInfoList.filter((item) => {
        return item.direction == 'Target'
      })
      if (rootState.excelProject.differentHighLightModel) {
        arr = []
      }
      let allowLine = ['SPELL_CHECK', 'RULE_LOWE_CHECK', 'SYNTAX_CHECK'];
      arr.forEach((item) => {
        if (
          allowLine.includes(item.checkRule) &&
          item.errorCode != '2101' &&
          item.errorStartPos <= item.errorEndPos
        ) {
          if (document.getElementsByClassName(item.id).length) {
            //存在则不划线；
          } else {
            let errorStartPos = item.errorStartPos
            let errorEndPos = item.errorEndPos + 1
            qaFormatText(targetNode, errorStartPos, errorEndPos, item.id, 1)
          }

        }


        // console.log(item.checkRule)
        if (item.checkRule == 'FORMAT_CHECK') {

          item.businessList?.forEach(citem=>{
            imgFormatText(targetNode,citem,item.id);
          })
          // let imgNode = document.getElementById('m1_1-shp-1');
        }
        //需要加上标签画线； 缺少标签画原文上面 ； 标签交叉 画译文，标签反向画译文；
        // if (item.checkRule)
        // let errorStartPos = item.errorStartPos == 0?item.errorStartPos:item.errorStartPos+1;
      })
    }
  },
  //加载原文QA
  renderSourceHtml({ rootState }, params) {
    let sentId = params.sentId
    if (!sentId) {
      //先取出一遍原文HTML //开始判断是否加载了术语？ 还是直接赋值？术语的值我应该存着？
      return
    }
    let sentenceIndex = rootState.excelProject.ArrMap.get(params.sentId)
    let sentenceItem =
      rootState.excelProject.docData.sentenceDTOList[sentenceIndex]
    // console.log(sentenceItem);

    if (!sentenceItem) {
      return
    }
    let sourceNode = window.$getSourceDom(sentenceItem.sentId);
    //先重新赋值；再进行改动；
    sourceNode.innerHTML =
      sentenceItem.termTagSource || sentenceItem.sourcePlaceholder
    if (sentenceItem.qaInfoList?.length) {
      //换成QAInfoList;遍历查找属于原文节点. 然后重新计算。
      let arr = sentenceItem.qaInfoList.filter((item) => {
        return item.direction == 'Source'
      })
      if (rootState.excelProject.differentHighLightModel) {
        arr = []
      }
      // console.log("执行qa",arr)
      arr.forEach((item) => {
        if (document.getElementsByClassName(item.id).length) {
          //存在则不划线；
          return
        }
        if (item.checkRule == 'FORMAT_CHECK') {
          item.businessList?.forEach(citem=>{
            imgFormatText(sourceNode,citem,item.id);
          })
        } else {
          let errorStartPos = item.errorStartPos
          let errorEndPos = item.errorEndPos + 1
          qaFormatText(sourceNode, errorStartPos, errorEndPos, item.id, 1);
        }
      })
    }

  },
  confirmSentences({ rootState }, params) {
    return new Promise((resolve, reject) => {
      confirmSentences(
        Object.assign(
          {
            docId: rootState.taskDetail.docId,
          },
          params
        )
      )
        .then((res) => {
          resolve(res)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  twinslatorMatch({ commit, rootState, dispatch,state }, params) {
    return new Promise(async (resolve, reject) => {
      let excelProject = rootState.excelProject
      let tabActiveItem
      if (params?.id) {
        let sentenceDTOListLength = excelProject.docData.sentenceDTOList.length
        for (let i = 0; i < sentenceDTOListLength; i++) {
          if (excelProject.docData.sentenceDTOList[i].id == params.id) {
            tabActiveItem = excelProject.docData.sentenceDTOList[i]
            break
          }
        }
      } else {
        tabActiveItem =
          excelProject.docData.sentenceDTOList[excelProject.tabActiveIndex]
      }

      if (!tabActiveItem) {
        return
      }
      let sourceNode = window.$getSourceDom(tabActiveItem.sentId);

      let sentId = tabActiveItem?.sentId;


      commit('changeKey', {
        twinslatorMatch: {
          //术语匹配列表
          matchedTerm:[],
          //语料匹配列表
          matchedCorpus:[],
        },
      })


      postTwinslatorCorpusMatch({sentId}).then(res=>{
        let matchList = res.data.data.matchList;
        state.twinslatorMatch.matchedCorpus = matchList || [];

      })

      let termResult = await postTwinslatorTermMatch ({
        sentId
      })

      // console.log(termResult)

      let result = termResult.data.data;
      //如果选中非调用请求的句子，则停止只赋值
      if (sentId != rootState.checkedDetail.sentId) {
        return
      }
      //如果打开原文编辑 ，则停止更新值
      if (rootState.openEditSourceText) {
        return false
      }

      //进行一遍转化赋值
      result.sentWithTag = window.$assignValueToSrc(result.sentWithTag);


      state.twinslatorMatch.matchedTerm = result.matchList || [];


      let termSource = result.sentWithTag
      //判断上一次是否已经加载过了。加载过则不需要再次加载 未加载则需要重新加载 并且重新赋值术语。
      if (
        tabActiveItem.termTagSource &&
        termSource === tabActiveItem.termTagSource
      ) {
        return
      }

      //如果是第一次加载 且没有匹配术语也不执行
      if (!tabActiveItem.termTagSource && !result.matchList?.length) {
        return
      }

      let positionObj = {
        cursorIndex:-1
      };

      try {
        //需要获取是光标还是范围选中
        let selection = window.getSelection();
        //如果是选中需要获取结束位置
        if (selection.type == 'Range') {
          positionObj = getRangePosition(sourceNode);
        } else if(selection.type == 'Caret') {
          //需要区别是光标定位还是范围选择；有可能是范围选择；
          positionObj.cursorIndex = getCursorPosition(sourceNode);
        }
      } catch (rej) {

      }

      // if (termSource != tabActiveItem.termTagSource && )

      tabActiveItem.termTagSource = termSource
      tabActiveItem.matchedTerm = [...result.matchList]


      //需要对比 术语可能更换，也可能未更换，直接实时查，不取缓存数据。
      dispatch('renderSourceHtml', {
        sentId: tabActiveItem.sentId,
        termHTML: termSource,
      })

      if (positionObj.type == 'range') {
        rangeSelection(sourceNode,positionObj.anchorIndex,positionObj.focusIndex);
      } else {
        if (positionObj.cursorIndex>-1) {
          positionSelection(sourceNode, positionObj.cursorIndex)
        }
      }
    })
  },
}

export default {
  namespaced: true,
  actions,
  mutations,
  getters,
  state,
}
