<template>
  <div
    class="li-edit"
    :class="{
      disabled: disabled || !taskDetail.canRichTextEdit || checkedDetail.locked || docDisabled,
    }"
  >
    <a-tooltip>
      <template #title>
        {{ isWindow ? 'Alt/Ctrl + B' : 'Control/⌘ + B' }}
      </template>
      <a-button class="edit-icon" @mousedown="editTargetText('jiacu')">
        <icon-font type="icon-jiacu"></icon-font>
      </a-button>
    </a-tooltip>
    <a-tooltip>
      <template #title>
        {{ isWindow ? 'Alt/Ctrl + I' : 'Control/⌘ + I' }}
      </template>
      <a-button class="edit-icon" @mousedown="editTargetText('xieti')">
        <icon-font type="icon-xieti"></icon-font>
      </a-button>
    </a-tooltip>
    <a-tooltip>
      <template #title>
        {{ isWindow ? 'Alt/Ctrl + U' : 'Control/⌘ + U' }}
      </template>
      <a-button class="edit-icon" @mousedown="editTargetText('xiahuaxian')">
        <icon-font type="icon-xiahuaxian"></icon-font>
      </a-button>
    </a-tooltip>
    <a-tooltip>
      <template #title>
        {{ isWindow ? 'Alt + Shift + =' : 'Control + Shift + =' }}
      </template>
      <a-button class="edit-icon" @mousedown="editTargetText('shangjiaobiao')">
        <icon-font type="icon-shangjiaobiao"></icon-font>
      </a-button>
    </a-tooltip>
    <a-tooltip>
      <template #title>
        {{ isWindow ? 'Alt + =' : 'Control + =' }}
      </template>
      <a-button class="edit-icon" @mousedown="editTargetText('xiajiaobiao')">
        <icon-font type="icon-xiajiaobiao"></icon-font>
      </a-button>
    </a-tooltip>
    <div class="edit-icon">
      <a-dropdown :trigger="['hover']">
        <template #overlay>
          <div class="clearTargetText">
            <div @click="excelTargetUpCase(7)">句首字母大写</div>
            <div @click="excelTargetUpCase(5)">全部大写</div>
            <div @click="excelTargetUpCase(6)">全部小写</div>
            <div @click="excelTargetUpCase(3)">所有单词首字母大写</div>
            <div @click="excelTargetUpCase(4)">所有单词首字母小写</div>
            <div @mousedown="excelTargetUpCase(1)" v-show="!checkedList.length">
              选中内容首字母大写
            </div>
            <div @mousedown="excelTargetUpCase(2)" v-show="!checkedList.length">
              选中内容首字母小写
            </div>
          </div>
        </template>
        <a-button class="edit-icon" @mousedown="visibleClick">
          <icon-font type="icon-zifu"></icon-font>
          <icon-font
            type="icon-xiala"
            class="svg-color-AAA font-size-12 downOutlined"
          ></icon-font>
        </a-button>
        <!--        <div @click="visibleClick">-->
        <!--          <icon-font type="icon-zifu"></icon-font>-->
        <!--          <DownOutlined class="svg-color-AAA downOutlined" />-->
        <!--        </div>-->
      </a-dropdown>
    </div>
  </div>
</template>

<script>
  import {
    computed,
    reactive,
    toRefs,
    createVNode,
    ComputedRef,
    getCurrentInstance,
    ref,
  } from 'vue'
  import { useStore } from 'vuex'
  import { useI18n } from 'vue-i18n'
  import { message } from 'ant-design-vue'
  import filterStrings from '@/plugins/filterString'
  import useProject from '@/components/source/useProject'
  import { DownOutlined } from '@ant-design/icons-vue'
  import { updateBatchTarget, updateSourceTarget } from '@/http/api'
  export default {
    name: 'index.vue',
    props: ['disabled'],
    components: {
      DownOutlined,
    },
    setup() {
      const { appContext } = getCurrentInstance()
      const globalProxy = appContext.config.globalProperties
      const { t, locale } = useI18n()
      const store = useStore()
      const { confirmSentences, sendCommitTargetText } = useProject()
      const taskDetail = computed(() => store.state.taskDetail)
      const checkedDetail = computed(() => {
        return store.state.checkedDetail
      })
      const batchDocList = computed(()=>{
        return store.state.batchDocList
      })
      const checkedList = computed(() => store.state.excelProject.checkedList)
      //获取当前激活项item
      const getTableActiveItem = () => {
        const sentenceDTOList = store.state.excelProject.docData.sentenceDTOList
        const tabActiveIndex = store.state.excelProject.tabActiveIndex
        return sentenceDTOList[tabActiveIndex]
      }

      const docDisabled = computed(()=>{
        // if (batchDocList.value[checkedDetail.value.sentId] != 'srt') {
        //   e.preventDefault();
        // }
        // return
        return false
      })

      const visibleClick = () => {}

      //富文本编辑进行操作译文提交
      const editTargetText = (type) => {
        if (type == 'jiacu') {
          document.execCommand('bold', false, null)
        } else if (type == 'xieti') {
          document.execCommand('Italic', false, null)
        } else if (type == 'xiahuaxian') {
          document.execCommand('Underline', false, null)
        } else if (type == 'shangjiaobiao') {
          document.execCommand('Superscript', false, null)
        } else if (type == 'xiajiaobiao') {
          document.execCommand('Subscript', false, null)
        } else {
          return true
        }
      }


      const getSelectHtml = () => {
        let selectionObj = window.getSelection()
        let rangeObj = selectionObj.getRangeAt(0)
        let docFragment = rangeObj.cloneContents()
        let tempDiv = document.getElementById('create-html-test-node')
        tempDiv.innerHTML = ''
        tempDiv.appendChild(docFragment)
        let selectedHtml = tempDiv.innerHTML
        return selectedHtml
      }

      const replaceSelectNode = (type = 1) => {
        let selectHtml = getSelectHtml()
        // type 1 是大写 2 为小写
        // let html = getEditHtml(type);
        if (!selectHtml) {
          return
        }
        let html = ''
        if (type == 1) {
          html = caseAlgorithmHtml(selectHtml, 'upperCase')
        } else {
          html = caseAlgorithmHtml(selectHtml, 'lowerCase')
        }

        //优化改动
        document.execCommand('insertHTML', false, html)
      }

      //替换字符串的指定索引位置；
      const replaceIndexStr = (str, index, char) => {
        const strAry = str.split('')
        strAry[index] = char
        return strAry.join('')
      }
      const replaceCharAtIndex=(str, index, newChar)=>{
        let leftPart = str.substring(0, index);
        let rightPart = str.substring(index + 1);
        return leftPart + newChar + rightPart;
      }

      const caseAlgorithmHtml = (innerHTML, transformType) => {
        // transformType: allUpperCase,allLowerCase,upperCase,lowerCase
        let originHTML = innerHTML || "";

        let tagReg = /<.*?>/gi
        let tagArr = originHTML.match(tagReg) || [];
        let textArr = originHTML.split(tagReg) || [];
        //记录每一组长度；
        let lengthArr = textArr.map(item=>{
          return item.length
        })
        console.log(tagArr,lengthArr)
        //开始处理大小写；
        let innerText = textArr.join("");
        //空格分词
        let wordArr = innerText.split(" ");
        let newWordArr = wordArr.map(item=>{
          let firstChar = item.charAt(0);
          if (firstChar) {

            if (transformType == 'allUpperCase') {
              //全部大写
              return item.toUpperCase();
            } else if (transformType == 'allLowerCase') {
              //全部小写
              return item.toLowerCase();
            } else if (transformType == 'upperCase') {
              //单词大写
              return replaceCharAtIndex(item,0,firstChar.toUpperCase())
            } else if (transformType == 'lowerCase') {
              //单词小写
              return replaceCharAtIndex(item,0,firstChar.toLowerCase())
            } else  {
              return item
            }
          } else {
            return item
          }
        })
        let newInnerText = newWordArr.join(" ");
        console.log(newInnerText);
        let startArr = [];
        lengthArr.forEach((item,index)=>{
          //获取起始点；
          let start = 0;
          for (let i = 0;i<index;i++) {
            start = start + lengthArr[i]
          }
          startArr.push(start);
        })
        let mergeTextArr = lengthArr.map((item,index)=>{
          console.log(startArr[index],startArr[index]+item)
          return newInnerText.substring(startArr[index],startArr[index]+item);
        })
        console.log(mergeTextArr)
        let mergeInnerHTML = ``;
        mergeTextArr.forEach((item,index)=>{
          let tagText = tagArr[index] || "";
          mergeInnerHTML = mergeInnerHTML + item + tagText;
        })
        console.log(mergeInnerHTML);
          //将newInnerText 以长度分隔；
        return mergeInnerHTML
        // console.log(newInnerHTMLArr.join(""));
      }

      const excelTargetUpCase = (e) => {
        // if (checkedList.valu)

        if (checkedList.value.length) {
          //批量
          batchEdit(e)
        } else {
          //单句
          singleEdit(e)
        }
      }

      const batchEdit = (e) => {
        let data = []
        //所有单词首字母大写
        if (e == 3) {
          data = checkedList.value.map((item) => {
            let newTargetPlaceholder = caseAlgorithmHtml(
              item.targetPlaceholder,
              'upperCase'
            )
            return {
              sentId: item.sentId,
              targetPlaceholder: newTargetPlaceholder,
              sourceHasChange: false,
              targetHasChange: true,
              targetText: filterStrings.getPureString(newTargetPlaceholder),
            }
          })
        }
        //所有单词首字母小写
        else if (e == 4) {
          data = checkedList.value.map((item) => {
            let newTargetPlaceholder = caseAlgorithmHtml(
              item.targetPlaceholder,
              'lowerCase'
            )
            return {
              sentId: item.sentId,
              targetPlaceholder: newTargetPlaceholder,
              sourceHasChange: false,
              targetHasChange: true,
              targetText: filterStrings.getPureString(newTargetPlaceholder),
            }
          })
        }
        //全部大写
        else if (e == 5) {
          data = checkedList.value.map((item) => {
            let newTargetPlaceholder = caseAlgorithmHtml(
              item.targetPlaceholder,
              'allUpperCase'
            )
            return {
              sentId: item.sentId,
              targetPlaceholder: newTargetPlaceholder,
              sourceHasChange: false,
              targetHasChange: true,
              targetText: filterStrings.getPureString(newTargetPlaceholder),
            }
          })
        }
        //全部小写
        else if (e == 6) {
          data = checkedList.value.map((item) => {
            let newTargetPlaceholder = caseAlgorithmHtml(
              item.targetPlaceholder,
              'allLowerCase'
            )
            return {
              sentId: item.sentId,
              targetPlaceholder: newTargetPlaceholder,
              sourceHasChange: false,
              targetHasChange: true,
              targetText: filterStrings.getPureString(newTargetPlaceholder),
            }
          })
        }
        //句子首字母大写
        else if (e == 7) {
          data = checkedList.value.map((item) => {
            let newTargetPlaceholder = replaceIndexStr(
              item.targetPlaceholder,
              0,
              item.targetPlaceholder.slice(0, 1).toLocaleUpperCase()
            )
            return {
              sentId: item.sentId,
              targetPlaceholder: newTargetPlaceholder,
              sourceHasChange: false,
              targetHasChange: true,
              targetText: filterStrings.getPureString(newTargetPlaceholder),
            }
          })
        } else {
          return
        }

        store.commit('changeKey', {
          screenLoading: true,
        })
        updateBatchTarget({
          data: JSON.stringify({ docId: taskDetail.value.docId, data: data }),
        })
          .then((res) => {})
          .finally(() => {
            store.dispatch('getSentence').then((res) => {
              store.commit('changeKey', {
                screenLoading: false,
              })
            })
          })
      }

      const singleEdit = (e) => {
        let item = getTableActiveItem()
        let sentDom = window.$getTargetDom(item.sentId)
        let innerHTML = filterStrings.getString(sentDom.innerHTML)
        let newInnerHTML = ''
        //选中内容首字母大写
        if (e == 1) {
          replaceSelectNode(1)
          return true
        }
        //选中内容首字母小写
        else if (e == 2) {
          replaceSelectNode(2)
          return true
        }
        //所有单词首字母大写
        else if (e == 3) {
          newInnerHTML = caseAlgorithmHtml(innerHTML, 'upperCase')
          sentDom.innerHTML = newInnerHTML
        }
        //所有单词首字母小写
        else if (e == 4) {
          newInnerHTML = caseAlgorithmHtml(innerHTML, 'lowerCase')
          sentDom.innerHTML = newInnerHTML
        }
        //全部大写
        else if (e == 5) {
          newInnerHTML = caseAlgorithmHtml(innerHTML, 'allUpperCase')
          sentDom.innerHTML = newInnerHTML
        }
        //全部小写
        else if (e == 6) {
          newInnerHTML = caseAlgorithmHtml(innerHTML, 'allLowerCase')
          sentDom.innerHTML = newInnerHTML
        } else if (e == 7) {
          newInnerHTML = replaceIndexStr(
            innerHTML,
            0,
            innerHTML.slice(0, 1).toLocaleUpperCase()
          )
          sentDom.innerHTML = newInnerHTML
        } else {
          return true
        }

        //做一下延时，防止和失去焦点提交进行了同时提交
        setTimeout(() => {
          sendCommitTargetText(item, false)
        }, 250)
      }

      return {
        docDisabled,
        isWindow: computed(() => {
          return store.state.isWindow
        }),
        checkedList,
        visibleClick,
        excelTargetUpCase,
        editTargetText,
        t,
        checkedDetail,
        taskDetail,
      }
    },
  }
</script>

<style scoped lang="less">
  .clearTargetText {
    border: 1px #dddddd solid;
    border-radius: 4px;
    background: white;
    //padding: 4px 0;
    div {
      font-size: 12px;
      color: #333;
      border-radius: 4px;
      padding: 4px;
      &:hover {
        background: #f3f4f7;
        cursor: pointer;
      }
    }
  }
</style>
