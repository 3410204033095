<template>
  <a-dropdown placement="bottomRight" v-model:visible="visible">
    <a @click.prevent v-show="!disabled">
      <a-button
        class="li"
        type="link"
        @click="selectFunc()"
        :class="{ disabled: disabled }"
      >
        <a-badge
          :count="isFilter"
          :offset="[5, 4]"
          :number-style="{
            backgroundColor: '#F55c3F',
            padding: '0 4px',
            minWidth: '16px',
            height: '16px',
            lineHeight: '15px',
            marginTop:'6px'
          }"
        >
          <icon-font class="font-size-16" type="icon-shaixuan"></icon-font>
        </a-badge>
        <p class="li-txt">筛选</p>
      </a-button>
    </a>
    <template #overlay>
      <div class="filter-room">
        <ul>
          <li
            v-for="(item, index) in store.state.excelProject.filterArr.filter(item=>{return !taskDetail.isSelfWork || (taskDetail.isSelfWork  && !item.selfWorkHidden)})"
            :key="index"
          >
            <div class="children">
              <div class="title">{{ item.title }}</div>
              <div class="check-box">
                <div
                  class="check-box-item"
                  v-for="(citem, cindex) in filterChildren(item.children)"
                  :key="cindex"
                >
                  <a-tooltip :title="citem.tip" v-if="citem.type=='rangeInput'">
                    <a-checkbox
                        v-model:checked="citem.isChecked"
                        @change="onFilterArrChange"
                        :disabled="citem.disabled"
                    >

                      <span class="input-number">
                        <a-input-number :disabled="!citem.isChecked" style="width: 30px;font-size: 12px;padding:0 2px;height:18px;line-height: 16px" size="small" v-model:value="citem.rangeValue[0]" :min="50" :max="100" :precision="0" @blur="onChange1(citem.rangeValue)"></a-input-number>
                        -
                        <a-input-number :disabled="!citem.isChecked" style="width: 30px;font-size: 12px;padding:0 2px;height: 18px;line-height: 16px" size="small" v-model:value="citem.rangeValue[1]" :min="50" :max="100" :precision="0" @blur="onChange2(citem.rangeValue)"></a-input-number>
                      </span>
                      %匹配
                    </a-checkbox>
                    <QuestionCircleOutlined style="font-size: 12px" v-if="citem.icon"/>
                  </a-tooltip>
                  <a-tooltip :title="citem.tip" v-else>
                    <a-checkbox
                      v-model:checked="citem.isChecked"
                      @change="onFilterArrChange"
                      :disabled="citem.disabled"
                    >
                      {{ citem.label }}
                    </a-checkbox>
                    <QuestionCircleOutlined style="font-size: 12px" v-if="citem.icon"/>
                  </a-tooltip>
                </div>
              </div>
            </div>
<!--            <a-divider-->
<!--              v-show="index != store.state.excelProject.filterArr.length - 1"-->
<!--            />-->
          </li>
        </ul>
        <div class="action">
          <a-button @click.stop="resetFilter">清空</a-button>
          <a-button
            type="primary"
            @click.stop="
              () => {
                onFilterArrChange()
                visible = false
              }
            "
            class="margin-l-10"
          >
            查询
          </a-button>
        </div>
      </div>
    </template>
  </a-dropdown>
</template>

<script>
  import { computed, getCurrentInstance, ref, watch } from 'vue'
  import { useStore } from 'vuex'
  import { useI18n } from 'vue-i18n'
  import { message } from 'ant-design-vue'
  import useUtils from '@/plugins/utils'
  import { getLastModifiedSent } from '@/http/api'
  import { positionSentence } from '@/plugins/comm'
  import {QuestionCircleOutlined} from '@ant-design/icons-vue';

  export default {
    name: 'index.vue',
    props: ['disabled'],
    components: {
      QuestionCircleOutlined
    },
    setup() {
      const { t } = useI18n()
      const { appContext } = getCurrentInstance()
      const globalProxy = appContext.config.globalProperties
      const store = useStore()
      const { setFocusEnd } = useUtils()
      const checkedDetail = computed(() => {
        return store.state.checkedDetail
      })
      const taskDetail = computed(()=>{
        return store.state.taskDetail
      })

      const onFilterArrChangeTimer = ref()
      const docData = computed(() => store.state.excelProject.docData)
      const visible = ref(false)
      let isFilter = ref(0)

      const onChange1=(list)=>{
        //失去焦点触发
        // console.log("失去焦点触发")
        let val = list[0]
        let reg = !isNaN(parseFloat(val)) && isFinite(val);
        if (!reg) {
          list[0] = 99;
          onFilterArrChange();
          return
        }
        if (list[0]>list[1]) {
          message.warning("语料匹配度搜索范围输入，不可前面大于后面");
          list[0] = list[1];
        }
        onFilterArrChange();
      }
      const onChange2=(list)=>{
        let val = list[1];
        let reg = !isNaN(parseFloat(val)) && isFinite(val);
        if (!reg) {
          list[1] = 100;
          onFilterArrChange();
          return
        }
        if (list[0]>list[1]) {
          message.warning("语料匹配度搜索范围输入，不可前面大于后面");
          list[1] = list[0];
        }
        onFilterArrChange();
      }
      //开始过滤
      const startFilter = () => {
        // store.commit("excelProject/resetFilterValue");
        store.commit('excelProject/getFilterValue', {})
        if (
          !store.state.excelProject.targetText &&
          !store.state.excelProject.sourceText &&
          isFilter.value == 0
        ) {
          getLastModifiedSent({ sentListSize: store.state.pageSize }).then(
            (res) => {
              let result = res.data.data
              if (result.modified) {
                store.commit('changeKey', {
                  pageNum: result.page,
                })
                store
                  .dispatch('getSentence', {
                    loading: true,
                    sentencePosition: result.sentId,
                  })
                  .then((res) => {
                    positionSentence(result.sentId)
                  })
              } else {
                store.commit('changeKey', {
                  pageNum: 1,
                })
                store.dispatch('getSentence', {
                  isBackTop: true,
                  loading: true,
                })
              }
            }
          )
        } else {
          //初始化操作
          store.commit('changeKey', {
            pageNum: 1,
          })
          store.dispatch('getSentence', { isBackTop: true })
        }
      }

      const onFilterArrChange = () => {
        if (onFilterArrChangeTimer.value) {
          clearTimeout(onFilterArrChangeTimer.value)
        }
        onFilterArrChangeTimer.value = setTimeout(() => {
          startFilter()
        }, 800)
      }

      //重置过滤
      const resetFilter = () => {
        store.commit('excelProject/resetFilterValue')
        isFilter.value = 0
        setTimeout(() => {
          startFilter()
        }, 600)
        visible.value = false
      }

      const filterChildren=(list)=>{
        return list.filter(item=>{
          if (!taskDetail.value.isSelfWork || (taskDetail.value.isSelfWork  && !item.selfWorkHidden)) {
            if (taskDetail.value.isSupportUrtLock) {
              return true
            } else {
              if (!item.isSupportUrtLock) {
                return true
              } else {
                return false
              }
            }
          } else {
            return  false
          }
        })
      }

      watch([store.state.excelProject.filterArr], (newValue, oldValue) => {
        isFilter.value = 0
        store.state.excelProject.filterArr.forEach((item) => {
          item.children.forEach((citem) => {
            if (citem.isChecked) {
              isFilter.value++
            }
          })
        })
      })

      const selectFunc = () => {}
      return {
        filterChildren,
        onChange1,
        onChange2,
        // adminTargetStatusSet,
        isFilter,
        visible,
        resetFilter,
        onFilterArrChangeTimer,
        onFilterArrChange,
        t,
        store,
        docData,
        taskDetail:taskDetail,
        selectFunc,
        checkedDetail,
      }
    },
  }
</script>

<style scoped lang="less">
.input-number{
  ::v-deep(.ant-input-number-handler-wrap){
    display: none;
  }
  ::v-deep(.ant-input-number-input) {
    padding:0;
    height: 100%;
  }
}
  .filter-room {
    width: 820px;
    background: white;
    padding: 10px 20px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);

    .action {
      padding: 10px 0;
      text-align: center;
    }

    li:not(:last-child){
      border-bottom: 1px #eee solid;
    }
    li {
      padding-top: 10px;
      .ant-divider-horizontal {
        margin: 0 0 4px 0;
      }

      .children {
        display: flex;
      }

      .title {
        width: 80px;
        flex-shrink: 0;
        font-weight: 600;
        color: #333333;
        font-size: 12px;
        line-height: 22px;
        //padding: 10px 0 10px 0;
      }

      .check-box {
        display: flex;
        flex-wrap: wrap;

        .check-box-item {
          min-width: 140px;
          margin-bottom: 10px;

          .ant-checkbox-wrapper {
            font-size: 12px;
          }
        }
      }
    }
  }
</style>
